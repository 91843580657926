import React, { Component } from "react";
import { Link } from "react-router-dom";

class Customers extends Component {
  render() {
    return (
      <div>
        <div className="thumbnail" />
        <nav className="container">
          <div
            className="nav nav-tabs justify-content-center"
            id="nav-tab"
            role="tablist"
          >
            <Link
              className="nav-item nav-link nav-link-emg "
              to="/activities/engineering"
            >
              FIELD OF ENGINEERING
            </Link>
            <Link
              className="nav-item nav-link nav-link-emg "
              to="/activities/services"
            >
              SERVICE
            </Link>
            <Link
              className="nav-item nav-link nav-link-emg active"
              to="/activities/customers"
            >
              CLIENTS
            </Link>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div className="container">
              <h1 className="currentPageTitle mt-3 col-12 col-md-12 pl-04">
                CLIENTS
              </h1>
            </div>
            <div className="fluid-container highlight p-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 pl-04">
                    <span className="text-justify dejavu">
                      <p>
                        Engineering Monitoring Group serves different types of
                        local and international organizations represented in the
                        field of construction.
                      </p>
                      <p>
                        The company, from the day of establishment, has
                        cooperated with over 1000 clients and implemented over
                        4000 projects.
                      </p>
                      <p>
                        The total cost of projects implemented during the last
                        year is 800 million GEL.
                      </p>
                      <p>Engineering Monitoring Group cooperates with:</p>
                      <ul className="management">
                        <li className="text-justify special">
                          Various government structures and funds, these are:
                        </li>
                        <li className="text-justify ml-5 deph">
                          Ministry of Environmental Protection and Agriculture
                          of Georgia;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Roads Department of Georgia;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Georgian Co-Investment Fund;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Georgian Energy Development Fund;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Municipal Development Fund of Georgia;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Georgian Tourism Development Fund;
                        </li>
                        <li className="text-justify ml-5 deph">
                          International Fund for Agricultural Development;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Partnership Fund.
                        </li>
                        <li className="text-justify special">
                          20 municipalities of Imereti and Kakheti regions,
                          these are:
                        </li>
                        <li className="text-justify ml-5 deph">
                          Kutaisi municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Vani municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Terjola municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Sachkhere municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Tkibuli municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Tskaltubo municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Khoni municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Baghdati municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Zestafoni municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Samtredia municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Chiatura municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Kharagauli municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Telavi municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Akhmeta municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Gurjaani municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Dedoplistskaro municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Lagodekhi municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Sagarejo municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Sighnaghi municipality;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Kvareli municipality.
                        </li>
                        <li className="text-justify special">
                          Local and international construction, design and
                          financial organizations.
                        </li>
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Customers;
