import React, { Component } from "react";
import AboutHeader from "./shared/aboutHeader";

class WhoWeAre extends Component {
  render() {
    return (
      <div>
        <div className="thumbnail" />
        <AboutHeader active="whoweare" />

        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div className="container">
              <h1 className="currentPageTitle mt-3 col-12 col-md-12 pl-04">
                WHO WE ARE
              </h1>
              <h5 className="color-emg pb-4 col-12 col-md-12 pl-04">
                ENGINEERING MONITORING GROUP
              </h5>
            </div>
            <div className="fluid-container highlight p-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 pl-04">
                    <p className="text-justify par">
                      Engineering Monitoring Group was founded in 2016. The
                      company is a Type A inspection accredited body and
                      provides engineering consulting services in almost all
                      fields of construction.
                    </p>
                    <p className="text-justify par">
                      Since the very beginning, the company has established the
                      status of a leading inspection body on the Georgian market
                      by strict adherence to international standards, integrity
                      and loyalty to professional values. Engineering Monitoring
                      Group has gained the trust and respect in the construction
                      sector with its professionalism and quality service.
                      Constant development, attraction of local and
                      international intellectual resources and team work ensured
                      stable progress of the company and resulted in sharp
                      increase of the market share and competitiveness.
                    </p>
                    <p className="text-justify par">
                      Tbilisi head office and regional branches of Engineering
                      Monitoring Group serve construction sites throughout
                      Georgia without restriction.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default WhoWeAre;
