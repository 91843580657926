import React, { Component } from "react";
import { Link } from "react-router-dom";

class Services extends Component {
  render() {
    return (
      <div>
        <div className="thumbnail" />
        <nav className="container">
          <div
            className="nav nav-tabs justify-content-center"
            id="nav-tab"
            role="tablist"
          >
            <Link
              className="nav-item nav-link nav-link-emg "
              to="/activities/engineering"
            >
              FIELD OF ENGINEERING
            </Link>
            <Link
              className="nav-item nav-link nav-link-emg active"
              to="/activities/services"
            >
              SERVICE
            </Link>
            <Link
              className="nav-item nav-link nav-link-emg "
              to="/activities/customers"
            >
              CLIENTS
            </Link>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div className="container">
              <h1 className="currentPageTitle mt-3 col-12 col-md-12 pl-04">
                SERVICE
              </h1>
            </div>
            <div className="fluid-container highlight p-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 pl-04">
                    <span className="text-justify dejavu">
                      <ul className="management">
                        <li className="text-justify special">
                          Inspection of the project, examination;
                        </li>
                        <li className="text-justify special">
                          Inspection of construction site expenditure,
                          examination;
                        </li>
                        <li className="text-justify special">
                          Inspection of completed works, examination;
                        </li>
                        <li className="text-justify special">
                          Inspection of technical conditions, examination;
                        </li>
                        <li className="text-justify special">
                          Inspection of fire safety, examination;
                        </li>
                        <li className="text-justify special">
                          Inspection of road safety, examination;
                        </li>
                        <li className="text-justify special">
                          Inspection of HSE safety, examination;
                        </li>
                        <li className="text-justify special">
                          Technical supervision of construction works;
                        </li>
                        <li className="text-justify special">
                          Management of construction:
                        </li>

                        <li className="text-justify ml-5 deph">
                          Management of quality, volumes, costs and deadlines of
                          construction works (control, monitoring, evaluation);
                        </li>
                        <li className="text-justify ml-5 deph">
                          Project management;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Contract management;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Risk management;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Conduction of construction logistics;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Data recording, management and control.
                        </li>

                        <li className="text-justify special">
                          Consulting services:
                        </li>
                        <li className="text-justify ml-5 deph">
                          Management and control of construction logistics;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Construction and operational consultations;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Development of construction logistics and a program of
                          work of the volume of works to be performed,
                          management of work execution plan and data
                          registration;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Management, recording and control of construction
                          costs;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Development of bill of quantities and reports on
                          completed works;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Development of instructions of construction logistics;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Introduction and management of safety systems;
                        </li>
                        <li className="text-justify ml-5 deph">
                          Selection and evaluation of projects for
                          implementation of construction works.
                        </li>
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Services;
