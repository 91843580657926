import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import auth from "../services/authService";
import careerPlus from "../res/plus.png";
import Moment from "react-moment";

class Career extends Component {
  state = {};
  render() {
    const { careers, onDelete } = this.props;
    const isAdmin = auth.isAdmin();
    return (
      <React.Fragment>
        <div className="thumbnail"></div>
        <div className="container pb-4">
          <h1 className="currentPageTitle pl-04 ">CAREER</h1>
        </div>
        <div className="fluid-container highlight p-5">
          <div className="container">
            <p className="pl-04 text-justify">
              Human Resources Department ensures effective and proper
              utilization of human resources. HR contributes to a healthy and
              safe environment at the workplace. Putting the right person in the
              right job and keeping our employees happy and motivated are
              important for us. The human resource is the most important asset
              of an organization strengthening the company’s business operations
              and affecting organizational success. Hence, more attention and
              focus on employee professional growth and permanent development is
              highly essential. By fostering employees’ professional growth,
              sustainable development of the company is ensured. We are
              cooperating with several universities to attract young academics
              who are bringing fresh perspective and a different way of thinking
              to our business.
            </p>
            <p className="pl-04 text-justify">
              We are also eager to help and hire interns giving them a brilliant
              opportunity to develop their professional skills and experience
              and find out their strengths and weaknesses, what they like and
              what they want to do in future. According to the results of the
              performance appraisal, the young interns will continue to work for
              the company.{" "}
            </p>

            <p className="pl-04 text-justify">
              However, we are always keen to meet energetic and talented
              professionals who would like to join our team.
            </p>
            <p className="pl-04 text-justify">
              If you wish to be considered for any future positions, please send
              your CV and covering letter to: hr@emg.ge
            </p>
            {!careers.length && (
              <p className="pl-04 text-justify">
                There are currently no vacancies available.
              </p>
            )}
            {isAdmin && (
              <Link
                style={{ marginBottom: "10px" }}
                className="btn btn-primary"
                to="/career/new"
              >
                ADD CAREER
              </Link>
            )}
            <div
              className="mb-4 pl-04 accordion"
              id="accordion"
              role="tablist"
              aria-multiselectable="true"
            >
              {careers &&
                careers.map((career) => (
                  <div className="card career" key={career._id}>
                    <div
                      className="card-header"
                      role="tab"
                      id={"heading" + career._id}
                    >
                      <h2 className="mb-0 row justify-content-between">
                        <div className="col-sm-6">
                          <button
                            data-toggle="collapse"
                            data-parent="#accordion"
                            href={"#collapse" + career._id}
                            aria-expanded="false"
                            aria-controls={"collapse" + career._id}
                            className="btn btn-link career-tab text-dark"
                          >
                            <img src={careerPlus} alt="Career plus" />
                            {" " + career.title}
                          </button>

                          {isAdmin && (
                            <React.Fragment>
                              <Link
                                to={"/career/" + career._id}
                                className="mt-auto card-btn btn btn-primary"
                              >
                                EDIT CAREER
                              </Link>
                              <Button
                                onClick={() => onDelete(career)}
                                variant="btn card-btn btn-danger"
                                className="mt-auto"
                              >
                                REMOVE CAREER
                              </Button>
                            </React.Fragment>
                          )}
                        </div>
                        <p className="career-date m-2">
                          START:{" "}
                          <Moment format="D MMM YYYY" withTitle>
                            {career.published}
                          </Moment>
                        </p>
                        <p className="career-date m-2">
                          END:
                          <Moment format="D MMM YYYY" withTitle>
                            {career.endDate}
                          </Moment>
                        </p>
                      </h2>
                    </div>
                    <div
                      id={"collapse" + career._id}
                      className="collapse"
                      role="tabpanel"
                      aria-labelledby={"heading" + career._id}
                    >
                      <p className="card-body text-justify">
                        {career.description}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default Career;
