import React, { Component } from "react";
import { Link } from "react-router-dom";

class Engineering extends Component {
  render() {
    return (
      <div>
        <div className="thumbnail" />
        <nav className="container">
          <div
            className="nav nav-tabs justify-content-center"
            id="nav-tab"
            role="tablist"
          >
            <Link
              className="nav-item nav-link nav-link-emg active"
              to="/activities/engineering"
            >
              FIELD OF ENGINEERING
            </Link>
            <Link
              className="nav-item nav-link nav-link-emg "
              to="/activities/services"
            >
              SERVICE
            </Link>
            <Link
              className="nav-item nav-link nav-link-emg "
              to="/activities/customers"
            >
              CLIENTS
            </Link>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div className="container">
              <h1 className="currentPageTitle mt-3 col-12 col-md-12 pl-04">
                FIELD OF ENGINEERING
              </h1>
            </div>
            <div className="fluid-container highlight p-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 pl-04">
                    <span className="text-justify dejavu">
                      <ul className="management">
                        <li className="text-justify special">
                          Roads and highways;
                        </li>
                        <li className="text-justify special">Railway;</li>
                        <li className="text-justify special">
                          Sewage systems and water supply (including wastewater,
                          water treatment facilities and systems);
                        </li>
                        <li className="text-justify special">
                          Electrification;
                        </li>
                        <li className="text-justify special">
                          Heating/cooling/ventilation;
                        </li>
                        <li className="text-justify special">
                          Power engineering;
                        </li>
                        <li className="text-justify special">
                          Bridges, tunnels and other underground structures;
                        </li>
                        <li className="text-justify special">
                          Geotechnical engineering;
                        </li>
                        <li className="text-justify special">
                          Civil and industrial engineering/construction;
                        </li>
                        <li className="text-justify special">
                          Communication systems;
                        </li>
                        <li className="text-justify special">
                          Cities and urban engineering/construction;
                        </li>
                        <li className="text-justify special">
                          Aviation engineering/construction;
                        </li>
                        <li className="text-justify special">
                          Marine engineering/construction;
                        </li>
                        <li className="text-justify special">
                          Gas and oil systems.
                        </li>
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Engineering;
