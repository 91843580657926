import React, { Component } from "react";
import { Link } from "react-router-dom";
import Manager from "./manager";
import managerOne from "../../src/res/pavle.png";
import managerTwo from "../../src/res/tamaz.png";
import managerThree from "../../src/res/mikheil.png";
import managerFour from "../../src/res/gia.png";
import AboutHeader from "./shared/aboutHeader";

class Governance extends Component {
  render() {
    return (
      <div>
        <div className="thumbnail" />
        <AboutHeader active="governance" />

        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div className="container">
              <h1 className="currentPageTitle mt-3 col-12 col-md-12 pl-04">
                GOVERNANCE
              </h1>
              <h5 className="color-emg pb-4 col-12 col-md-12 pl-04">
                MANAGEMENT
              </h5>
            </div>
            <div className="fluid-container highlight p-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 pl-04">
                    <span className="text-justify dejavu">
                      Company management:
                      <br />
                      <br />
                      <ul className="management">
                        <li className="text-justify special">
                          Is based on the best international practice of
                          organizational corporate management;
                        </li>
                        <li className="text-justify special">
                          Enhances corporate culture and refines the
                          organization structure according to its goals;
                        </li>
                        <li className="text-justify special">
                          Cooperates with local, as well as foreign partners;
                        </li>
                        <li className="text-justify special">
                          Expands sectoral and geographical area of services;
                        </li>
                        <li className="text-justify special">
                          Considering international experience introduces to
                          Georgia modern technologies and innovations of
                          engineering and construction proven in the world.
                        </li>
                      </ul>
                      Constant growth and development is the core principle of
                      our team.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="fluid-container highlight">
            <div className="container highlight pb-5 pt-3 pr-5 pl-5">
              <div className="row">
                <Manager
                  name="IOVANE SIKHARULIDZE"
                  img={
                    "https://www.revixpert.ch/app/uploads/portrait-placeholder.jpg"
                  }
                  position="GENERAL DIRECTOR"
                  id="manager1"
                  profession="Mathematician, Financier"
                  education={[
                    "Georgian Technical University – Master’s degree in Banking",
                    "Tbilisi State University – Master’s degree in Applied Mathematics and Computer Science",
                  ]}
                  experience="17-year work experience on the leading positions in banking sector"
                  work={{
                    pretext: "Field of activity",
                    text: "over 5 years he has been working in the field of technologies, project management, investment and consulting services, and for the last 3 years – in the direction of inspection, expertise and supervision",
                  }}
                ></Manager>
                <Manager
                  name="GIA CHANTLADZE"
                  position="DIRECTOR"
                  img={managerFour}
                  id="manager2"
                  profession="Engineer"
                  education={[
                    "Georgian Polytechnic Institute – Master’s degree in Highway Engineering",
                  ]}
                  experience="40-year experience of working on the leading positions in state and private
                  organizations"
                  work={{
                    pretext: "Implemented projects",
                    text: "around 20 large scale projects of construction-rehabilitation and maintenance of roads, bridges and tunnels in Georgia have been implemented under his immediate supervision",
                  }}
                ></Manager>
                <Manager
                  name="MIKHEIL KIKOLASHVILI"
                  position="DIRECTOR"
                  id="manager3"
                  img={managerThree}
                  profession="Engineer"
                  education={[
                    "Georgian Technical University – Master’s degree in Engineering",
                    "Georgian Technical University, faculty of Construction – Ph.D",
                    "Georgian Technical University, faculty of Mining and Geology – student",
                  ]}
                  experience="10-year work experience in construction field in Georgia, as well as in other
                  countries"
                  work={{
                    pretext: "Implemented projects",
                    text: "his portfolio includes multi-functional hotels and residential buildings (Chief Designer), and structures of different function (Inspector) constructed in Georgia, France and Italy",
                  }}
                ></Manager>
                <Manager
                  name="TAMAZ TKHILAISHVILI"
                  position="MEMBER OF THE BOARD OF DIRECTORS"
                  id="manager4"
                  img={managerTwo}
                  profession="Civil engineer"
                  education={[
                    "Georgian Polytechnic Institute, faculty of Construction, Industrial and Civil Construction",
                  ]}
                  experience="50-year work experience in the field of construction in state and private
                  organizations"
                  work={{
                    pretext: "Implemented projects",
                    text: "for many years he has been working on sites of different functional purpose. As a Chief Specialist he managed dozens of projects in the capital and regions of Georgia",
                  }}
                ></Manager>
                <Manager
                  name="PAVLE SAKHANBERIDZE"
                  position="HEAD OF QUALITY MANAGEMENT DEPARTMENT"
                  id="manager5"
                  img={managerOne}
                  profession="Architect"
                  education={[
                    "University of Twente (Netherlands) – Master’s degree in Construction Management",
                    "KTH Royal Institute of Technology (Sweden) – graduate, Master program in Land Management",
                    "Georgian Technical University, faculty of Architecture and Urban Planning",
                  ]}
                  experience="20-year work experience in the field of architecture"
                  work={{
                    pretext: "Implemented projects",
                    text: "he has implemented around 15 large scale projects in Tbilisi and regions, among them are hotels, multi-functional buildings, residential buildings, reconstruction of historical structures etc",
                  }}
                ></Manager>
              </div>
              {/* /.row */}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Governance;
