import React, { Component } from "react";
import qualityAndPermits from "../res/qualityandpermits.png";
import employeeQualification from "../res/employeequalification.png";
import qualityManagement from "../res/qualitymanagement.png";
import healthAndSafetyManagement from "../res/healthandsafetymanagement.png";
import AboutHeader from "./shared/aboutHeader";

class QualityAndPermits extends Component {
  render() {
    return (
      <div>
        <div className="thumbnail" />
        <AboutHeader active="qualityandpermits" />

        <div
          className="tab-pane fade show"
          id="nav-quality-and-permits"
          role="tabpanel"
          aria-labelledby="nav-quality-and-permits-tab"
        >
          <div className="container">
            <h1 className="currentPageTitle mt-3 pl-04">
              QUALITY AND RESPONSIBILITY
            </h1>
          </div>
          <div className="fluid-container highlight">
            <div className="container">
              <div className="row pt-5 pb-5">
                <div className="col-sm-12 col-md-6 min-vh-25 ">
                  <div className="column text-center">
                    <img src={qualityAndPermits} alt="" />
                    <h5 className="p-2 color-emg">ACCREDITATION</h5>
                    <p className="text-justify">
                      Engineering Monitoring Group LLC is A type inspection
                      company accredited by LEPL "The Unified National Body of
                      Accreditation, Accreditation Center" by SST ISO / IEC
                      17020: 2012/2013 according to the international standards
                      is recognized by the EA (European Accreditation) General
                      Assembly. Accreditation certificate ID: GAC-IB-0320.
                    </p>
                    <p className="text-justify">
                      Also, Company successfully passed through all the
                      requirements of ISO certification in July 2021 received a
                      Certificate from The Certification Body of TÜV SÜD
                      Management Service GmbH on the 9th of August, 2021.
                      (Certificate Registration No.: 12 100 62504 TMS.)
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 min-vh-25 ">
                  <div className="column text-center">
                    <img src={employeeQualification} alt="" />
                    <h5 className="p-2 color-emg">INTERNATIONAL RELATIONS</h5>
                    <p className="text-justify">
                      The organization works closely with leading international
                      companies having similar profiles, as well as
                      organizations working in related fields. Intensive
                      exchange of experience allows extension of knowledge,
                      perfection of service and professional development of the
                      employees.
                    </p>
                    <p className="text-justify">
                      As a result of coordination of foreign and local partners,
                      Engineering Monitoring Group successfully implements
                      large-scale projects financed by foreign and local
                      investments and participates in large infrastructural
                      tenders.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 min-vh-25">
                  <div className="column text-center">
                    <img src={qualityManagement} alt="" />
                    <h5 className="p-2 color-emg">
                      QUALIFICATION OF EMPLOYEES
                    </h5>
                    <p className="text-justify">
                      Engineering Monitoring Group is staffed by highly
                      qualified and accredited local and foreign specialists
                      with many years of work experience: engineers, architects,
                      experts, supervisors, managers of technical and quality
                      control.
                    </p>
                    <p className="text-justify">
                      The company also offers risk management, business
                      development, legal and financial services, information
                      technology, marketing and public relations services.
                    </p>
                    <p className="text-justify">
                      The organization currently employs more than 350 people
                      and this number is constantly growing.
                    </p>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 min-vh-25">
                  <div className="column text-center">
                    <img src={healthAndSafetyManagement} alt="" />
                    <h5 className="p-2 color-emg">HEALTH AND SAFETY</h5>
                    <p className="text-justify">
                      Health and safety management system has been introduced in
                      the organization to ensure the health and safety of
                      employees. The company is guided by the European Standards
                      Directive 2013/35/EU, which implies identification,
                      assessment and control of risks in the work environment of
                      employees.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default QualityAndPermits;
