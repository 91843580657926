import React, { Component } from "react";
import { paginate } from "../utils/paginate";
import Pagination from "./common/pagination";
import { Link } from "react-router-dom";
import ProjectFlex from "./projectFlex";

class ProjectsOngoing extends Component {
  state = {
    projectsOngoing: [],
    categories: [],
    branches: [],
    pageSize: 2,
    currentPage: 1,
    searchQuery: "",
    selectedStyle: null,
    sortColumn: { path: "title", order: "asc" },
  };

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  getPagedData = () => {
    const { pageSize, currentPage } = this.state;
    let { projectsOngoing: allProjects } = this.props;

    const projects = paginate(allProjects, currentPage, pageSize);
    return { totalCount: allProjects.length, data: projects };
  };

  render() {
    const { length: count } = this.props.projectsOngoing;
    const { pageSize, currentPage } = this.state;
    const { user, onDelete, fullProjectsDone } = this.props;
    let isAdmin = false;
    if (user) isAdmin = user.isAdmin;

    if (count === 0)
      return (
        <React.Fragment>
          <div className="thumbnail" />
          <nav className="container">
            <div
              className="nav nav-tabs justify-content-center"
              id="nav-tab"
              role="tablist"
            >
              <Link
                className="nav-item nav-link nav-link-emg active"
                to="/projects/ongoing"
              >
                ONGOING PROJECTS
              </Link>
              <Link
                className="nav-item nav-link nav-link-emg  "
                to="/projects/done"
              >
                COMPLETED PROJECTS
              </Link>
            </div>
          </nav>
          <div>
            <Link
              style={{ marginBottom: "10px" }}
              className="btn btn-primary ml-1"
              to="/editProjectOngoing/new"
            >
              ADD ONGOING PROJECT
            </Link>
            <p>There are no ongoing projects added.</p>
          </div>
        </React.Fragment>
      );
    const { totalCount, data: projectsOngoing } = this.getPagedData();

    return (
      <React.Fragment>
        <div className="thumbnail" />
        <nav className="container">
          <div
            className="nav nav-tabs justify-content-center"
            id="nav-tab"
            role="tablist"
          >
            <Link
              className="nav-item nav-link nav-link-emg active"
              to="/projects/ongoing"
            >
              ONGOING PROJECTS
            </Link>
            <Link
              className="nav-item nav-link nav-link-emg  "
              to="/projects/done"
            >
              COMPLETED PROJECTS
            </Link>
          </div>
        </nav>
        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div className="container">
              <h1 className="currentPageTitle mt-3 col-12 col-md-12 pl-04">
                ONGOING PROJECTS
              </h1>
            </div>
            <div className="fluid-container highlight p-5">
              <div className="container">
                <div className="column text-center">
                  {fullProjectsDone.map((s) => (
                    <p>
                      <Link to={"/projects/ongoing/" + s._id}>{s.title}</Link>
                    </p>
                  ))}
                </div>
              </div>

              <div className="container ">
                {isAdmin && (
                  <Link
                    style={{ marginBottom: "10px" }}
                    className="btn btn-primary ml-1"
                    to="/editProjectOngoing/new"
                  >
                    ADD Ongoing PROJECT
                  </Link>
                )}

                {/* <SearchBox value={searchQuery} onChange={this.handleSearch} /> */}
                <ProjectFlex
                  category={"Ongoing"}
                  count={this.props.count}
                  onRenewBag={this.props.onRenewBag}
                  newss={projectsOngoing}
                  onDelete={onDelete}
                />
                <Pagination
                  itemsCount={totalCount}
                  pageSize={pageSize}
                  onPageChange={this.handlePageChange}
                  currentPage={currentPage}
                />
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default ProjectsOngoing;
