import React, { Component } from "react";
import { Link } from "react-router-dom";
import basicPrinciples from "../res/basicprinciples.png";
import ourGoals from "../res/ourgoals.png";
import ourMission from "../res/ourmission.png";
import priorities from "../res/priorities.png";
import AboutHeader from "./shared/aboutHeader";

class StrategicVision extends Component {
  render() {
    return (
      <div>
        <div className="thumbnail" />
        <AboutHeader active="strategicvision" />

        <div
          className="tab-pane fade show"
          id="nav-strategic-vision"
          role="tabpanel"
          aria-labelledby="nav-strategic-vision-tab"
        >
          <div className="container">
            <h1 className="currentPageTitle mt-3 col-12 col-md-12 pl-04">
              STRATEGIC VISION
            </h1>
          </div>
          <div className="fluid-container highlight">
            <div className="container ">
              <div className="row pt-5 pb-5">
                <div className="col-sm-12 col-md-6 min-vh-25">
                  <div className="column text-center">
                    <img src={ourMission} alt="" />
                    <h5 className="p-2 color-emg">MISSION</h5>
                    <div className="d-flex ">
                      <p className="text-justify">
                        <ul className="management ">
                          <li className="special">
                            Establish international standards on the local
                            market
                          </li>
                          <li className="special">
                            Establish the practice of making the best
                            engineering decisions
                          </li>
                          <li className="special">
                            Development of inspection, supervision and
                            construction sector in general, in line with the
                            innovative trends proven in the world’s leading
                            countries
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 min-vh-25 ">
                  <div className="column text-center">
                    <img src={ourGoals} alt="" />
                    <h5 className="p-2 color-emg">GOALS</h5>
                    <div className="d-flex ">
                      <p className="text-justify">
                        <ul className="management">
                          <li className="special">
                            Constant professional development
                          </li>
                          <li className="special">
                            Expand the scope of services
                          </li>
                          <li className="special">Provide quality service</li>
                          <li className="special">
                            Maintain the reputation of a trustworthy and
                            reliable company
                          </li>
                          <li className="special">
                            Be a leader on the Georgian market
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-sm-12 col-md-6 min-vh-25">
                  <div className="column text-center">
                    <img src={priorities} alt="" />
                    <h5 className="p-2 color-emg">PRIORITIES</h5>
                    <div className="d-flex ">
                      <p className="text-justify">
                        <ul className="management">
                          <li className="special">Impartiality</li>
                          <li className="special">Reliability</li>
                          <li className="special">High quality service</li>
                          <li className="special">Team work</li>
                          <li className="special">
                            Constant growth and development
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-sm-12 col-md-6 min-vh-25 ">
                  <div className="column text-center ">
                    <img src={basicPrinciples} alt="" />
                    <h5 className="p-2 color-emg">MAIN PRINCIPLES</h5>
                    <div className="d-flex ">
                      <p className="text-justify">
                        <ul className="management">
                          <li className="special">
                            Compliance with legal requirements and regulations
                          </li>
                          <li className="special">Integrity</li>
                          <li className="special">Independence</li>
                          <li className="special">Objectivity</li>
                          <li className="special">
                            Guidance in accordance with local and international
                            standards
                          </li>
                        </ul>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StrategicVision;
