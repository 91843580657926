import React, { Component } from "react";
import { Link } from "react-router-dom";
import AboutHeader from "./shared/aboutHeader";

class Training extends Component {
  render() {
    return (
      <div>
        <div className="thumbnail" />
        <AboutHeader active="trainings" />

        <div className="tab-content" id="nav-tabContent">
          <div
            className="tab-pane fade show active"
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <div className="container">
              <h1 className="currentPageTitle mt-3 col-12 col-md-12 pl-04">
                TRAININGS
              </h1>
            </div>
            <div className="fluid-container highlight p-5">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-12 pl-04 dejavu">
                    Within the Engineering Monitoring Group there is a training
                    center focused on continuous growth and development of
                    employees, raising the qualification, improving the quality
                    of the organization’s service and perfection of the
                    corporate culture.
                    <br /> <br />
                    <span className="text-justify">
                      Conducted trainings:
                      <br />
                      <br />
                      <ul className="management">
                        <li className="text-justify special">
                          Methods of implementation of the inspection process
                          (October 2019);
                        </li>
                        <li className="text-justify special">
                          Methods of implementation of the inspection process,
                          clarification-teaching, review of resources,
                          facilities, equipment and technical requirements (June
                          2019);
                        </li>
                        <li className="text-justify special">
                          Getting familiar with quality management system
                          documents (April 2019);
                        </li>
                        <li className="text-justify special">
                          Overview of the requirements of SST/IEC
                          17020:2012/2013 international standards (April 2019).
                        </li>
                      </ul>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Training;
